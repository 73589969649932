import { EmotionCache, withEmotionCache } from '@emotion/react'
import { createContext, useContext } from 'react'

const CacheContext = createContext<EmotionCache | undefined>(undefined)

export const useEmotionCache = () => useContext(CacheContext)

export const EmotionCacheProvider = withEmotionCache(
  ({ children }: { children: React.ReactNode }, cache: EmotionCache) => {
    return <CacheContext.Provider value={cache}>{children}</CacheContext.Provider>
  },
)
