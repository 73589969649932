import isPropValid from '@emotion/is-prop-valid'
import Ta from 'react-textarea-autosize'

import {
  BaseStyle,
  inputSpacing,
  inputValidationState,
  InputVariantProps,
} from '../forms/inputVariants'
import styled from '../styled'

export const Textarea = styled(Ta, {
  shouldForwardProp: prop => prop === 'minRows' || isPropValid(prop),
})<InputVariantProps>(
  BaseStyle,
  {
    flexGrow: 1,
  },
  inputSpacing,
  inputValidationState,
)
