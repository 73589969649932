import { Flex } from '../core/Flex'
import { MediaQueries } from '../ScreenSize'
import styled from '../styled'
import { space } from '../tokens'

/**
 * `ButtonRow` arranges any number of buttons in a row with even spacing.
 *
 * At smaller breakpoints it switches to stacking the buttons vertically.
 */
export const ButtonRow = styled(Flex)(
  {
    '& > button, & > a': {
      flex: '1 1',
      whiteSpace: 'nowrap',
      justifyContent: 'center',
      marginRight: space(4),
    },
    '& > :last-child': {
      marginRight: 0,
    },
    [MediaQueries.Small]: {
      flexShrink: 0,
      flexDirection: 'column-reverse',
      alignItems: 'stretch',
      '& > button, & > a': {
        marginBottom: space(4),
        flex: '1 0 0%',
        width: '100%',
        marginRight: 0,
        marginLeft: 0,
      },
    },
  },
  ({ children }) => {
    const n = (Array.isArray(children) && children.length) || 1
    return {
      '& > button, & > a': {
        flexBasis: `calc(${100 * (1 / n)}% - (${n - 1} * ${space(4)}/${n}))`,
      },
    }
  },
)

export default ButtonRow
