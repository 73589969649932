import styled from '../styled'
import { spacing, SpacingProps } from '../styleFunctions/spacing'

export const Separator = styled.hr<SpacingProps>(
  {
    color: 'rgba(0, 0, 0, 0.1)',
    borderTopStyle: 'none',
    borderBottomStyle: 'solid',
  },
  spacing,
)

Separator.defaultProps = { my: 4 }

export default Separator
