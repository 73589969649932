const datestampRgx = /^\d{4}-\d{2}-\d{2}$/
import { addMinutes } from 'date-fns'

export type Datestamp = string & { __datestamp__: void }

export function berlinTime(): Date {
  const now = new Date()
  const minutesDiff = 120 + now.getTimezoneOffset()
  return addMinutes(now, minutesDiff)
}

export function isDatestamp(s: string): s is Datestamp {
  return datestampRgx.test(s)
}

export function toDatestamp(d: Date): Datestamp {
  const year = d.getFullYear()
  const month = (d.getMonth() + 1).toString().padStart(2, '0')
  const day = d.getDate().toString().padStart(2, '0')
  return `${year}-${month}-${day}` as Datestamp
}

export function toDate(ds: Datestamp): Date {
  return new Date(ds)
}
