import { CSSObject } from '@emotion/react'

import { Theme } from '../themeTypes'
import {
  assignComputed,
  assignLiteral,
  assignToken,
  Responsive,
  ResponsiveCSS,
} from './assignStyles'

import { space } from '../tokens'

export interface GridContainerProps {
  /** Number of columns in this grid */
  columns?: Responsive<number>
  /** spacing between items in the grid */
  gridGap?: Responsive<number>
  /** Vertical space between items in the grid. This overrides `gridGap` */
  gridColumnGap?: Responsive<number | string>
  /** Horizontal space between items in the grid. This overrides `gridGap` */
  gridRowGap?: Responsive<number | string>
}

export function gridContainer(props: GridContainerProps & { theme: Theme }) {
  const rowGap = typeof props.gridRowGap === 'undefined' ? props.gridGap : props.gridRowGap
  const columnGap = typeof props.gridColumnGap === 'undefined' ? props.gridGap : props.gridColumnGap

  const styles: CSSObject = {
    '& input': { minWidth: '1em' },
  }

  assignComputed(styles, 'gridTemplateColumns', calculateColumnTemplate, props.columns)
  assignToken(styles, space, 'gridRowGap', rowGap)
  assignToken(styles, space, 'gridColumnGap', columnGap)

  return styles
}

const calculateColumnTemplate = (n: number) => `repeat(${n}, 1fr)`

export interface GridItemProps {
  gridArea?: ResponsiveCSS<'gridArea'>
  gridColumn?: ResponsiveCSS<'gridColumn'>
  gridColumnStart?: ResponsiveCSS<'gridColumnStart'>
  gridColumnEnd?: ResponsiveCSS<'gridColumnEnd'>
  gridRow?: ResponsiveCSS<'gridRow'>
  gridRowStart?: ResponsiveCSS<'gridRowStart'>
  gridRowEnd?: ResponsiveCSS<'gridRowEnd'>
}

export function gridItem(props: GridItemProps) {
  const styles: CSSObject = {}
  assignLiteral(styles, 'gridArea', props.gridArea)
  assignLiteral(styles, 'gridColumn', props.gridColumn)
  assignLiteral(styles, 'gridColumnStart', props.gridColumnStart)
  assignLiteral(styles, 'gridColumnEnd', props.gridColumnEnd)
  assignLiteral(styles, 'gridRow', props.gridRow)
  assignLiteral(styles, 'gridRowStart', props.gridRowStart)
  assignLiteral(styles, 'gridRowEnd', props.gridRowEnd)
  return styles
}
