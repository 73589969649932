import { FC, HTMLAttributes } from 'react'

import styled from '../styled'

import { Text, TextProps } from '../core/Text'

interface HeadingProps extends TextProps {
  level?: 1 | 2 | 3 | 4 | 5 | 6
}

/**
 * Heading renders `h1` through `h6` tags with appropriate default styles.
 *
 * @component
 */
export const Heading: FC<HeadingProps & HTMLAttributes<HTMLHeadingElement>> = ({
  level = 2,
  ...props
}) => {
  switch (level) {
    case 1:
      return <H1 {...props} />
    case 2:
      return <H2 {...props} />
    case 3:
      return <H3 {...props} />
    case 4:
      return <H4 {...props} />
    case 5:
      return <H5 {...props} />
    case 6:
      return <H6 {...props} />
    default:
      throw new Error('Invalid heading level')
  }
}

const IconStroke = {
  '& svg': {
    strokeWidth: '1.5',
    fontSize: '1.25em',
  },
}

const H1 = styled(Text.withComponent('h1'))(IconStroke)
const H2 = styled(Text.withComponent('h2'))(IconStroke)
const H3 = styled(Text.withComponent('h3'))(IconStroke)
const H4 = styled(Text.withComponent('h4'))(IconStroke)
const H5 = styled(Text.withComponent('h5'))(IconStroke)
const H6 = styled(Text.withComponent('h6'))(IconStroke)

H1.defaultProps = {
  fg: 'gray-100',
  mt: 0,
  lineHeight: 1.5,
  fontWeight: 400,
  fontSize: 6,
  letterSpacing: '0.01em',
}
H2.defaultProps = { ...H1.defaultProps, fontSize: 5, mb: 5 }
H3.defaultProps = { ...H1.defaultProps, fontSize: 4, mb: 4 }
H4.defaultProps = { ...H1.defaultProps, fontSize: 3 }
H5.defaultProps = { ...H1.defaultProps, fontSize: 2 }
H6.defaultProps = { ...H1.defaultProps, fontSize: 1 }
