import { CSSObject } from '@emotion/react'
import deepEqual from 'deep-equal'
import * as React from 'react'

import { InputProps } from '@dropscan/forms'

import Separator from '../components/Separator'
import { Flex } from '../core/Flex'
import Text from '../core/Text'
import Checkbox from './Checkbox'
import { Fieldset, Legend } from './Fieldset'
import { InputVariantProps } from './inputVariants'

export interface Props<T> extends Partial<InputProps<T[]>>, InputVariantProps {
  labelText?: React.ReactNode
  options: [T, React.ReactNode, boolean?][]
  styles?: CSSObject
  selectAllLabel?: React.ReactNode
}

/**
 * Checkboxes renders a list of labelled [`Checkbox`](#checkbox) components.
 */
export default class Checkboxes<T> extends React.PureComponent<Props<T>> {
  static of<U>(): React.ComponentType<Props<U>> {
    return this
  }

  render() {
    const {
      styles,
      options,
      value,
      error,
      disabled,
      labelText,
      spacing,
      validationState,
      selectAllLabel,
    } = this.props
    const stylingProps = { spacing, validationState, styles }
    const allSelected = value && value.length === options.length
    return (
      <Fieldset role="group">
        {labelText && <Legend {...stylingProps}>{labelText}</Legend>}
        <Flex flexDirection="column" mx={2}>
          {selectAllLabel && (
            <>
              <Checkbox
                key="select-all"
                labelText={selectAllLabel}
                value={allSelected}
                disabled={disabled}
                onChangeValue={this.handleChangeSelectAll}
              />
              <Separator my={1} />
            </>
          )}

          {error && <Text fg="danger-400">{error}</Text>}

          {options.map(([thisValue, label, thisDisabled], i) => (
            <Checkbox
              key={i}
              labelText={label}
              value={value ? value.some(v => deepEqual(v, thisValue)) : false}
              disabled={disabled || thisDisabled}
              onChangeValue={checked => this.handleChangeValue(thisValue, checked || false)}
            />
          ))}
        </Flex>
      </Fieldset>
    )
  }

  private handleChangeValue(item: T, checked: boolean) {
    const { value = [], onChangeValue, onBlur } = this.props
    const existingIdx = value.findIndex(other => deepEqual(other, item))
    const newValue = value.slice()
    if (existingIdx < 0 && checked) {
      newValue.push(item)
    } else if (existingIdx >= 0 && !checked) {
      newValue.splice(existingIdx, 1)
    } else {
      return
    }
    onChangeValue?.(newValue)
    onBlur?.()
  }

  private handleChangeSelectAll = (selectAll: boolean) => {
    const { onChangeValue, onBlur } = this.props
    if (selectAll) {
      onChangeValue?.(this.props.options.map(opt => opt[0]))
      onBlur?.()
    } else {
      onChangeValue?.([])
      onBlur?.()
    }
  }
}
