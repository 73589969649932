import deepEqual from 'deep-equal'
import * as React from 'react'

import { InputProps } from '@dropscan/forms'

import Label, { Props as LabelProps } from '../forms/Label'
import styled from '../styled'

export interface Props<T> extends Partial<InputProps<T>>, LabelProps {
  labelText?: React.ReactNode
  options: [T, string, boolean?][]
}

/**
 * `Select` shows a dropdown for selecting from an array of labelled options.
 * @component
 */
export default function Select<T>({
  labelText,
  options,
  disabled,
  value,
  validationState,
  onChangeValue,
  ...otherProps
}: Props<T>) {
  const htmlValue = options.findIndex(([v, _]) => deepEqual(v, value))
  const onChange = onChangeValue
    ? (evt: React.SyntheticEvent<HTMLSelectElement>) => {
        if (!onChangeValue) {
          return
        }
        const idx = parseInt(evt.currentTarget.value, 10)
        if (isNaN(idx) || idx >= options.length) {
          throw new Error(
            'programming error, change event should only produce integer values between 0 and options.length...',
          )
        }
        const [value /*, label */] = options[idx]
        onChangeValue(value)
      }
    : void 0
  return (
    <Label validationState={validationState}>
      {labelText}
      <SelectElement
        {...otherProps}
        disabled={disabled}
        value={htmlValue >= 0 ? htmlValue : undefined}
        onChange={onChange}
      >
        {options.map(([_, optLabel, disabled], idx) => (
          <option key={idx} value={idx} disabled={disabled}>
            {optLabel}
          </option>
        ))}
      </SelectElement>
    </Label>
  )
}

const SelectElement = styled('select')({})
