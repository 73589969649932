import { IndifferentHandler, keyboardClickable } from '@dropscan/util/keyboardClickable'
import { color, fontSize } from '@dropscan/ds/tokens'
import isPropValid from '@emotion/is-prop-valid'
import React, { HTMLAttributes, ReactNode, Ref } from 'react'

import styled from '../styled'
import { flexContainer, FlexContainerProps } from '../styleFunctions/flex'
import { gridItem, GridItemProps } from '../styleFunctions/grid'
import { spacing, SpacingProps } from '../styleFunctions/spacing'
import { variants } from '../styleFunctions/variants'
import { InputVariantProps } from './inputVariants'

interface StyleProps extends InputVariantProps, SpacingProps, FlexContainerProps, GridItemProps {}

export interface Props extends StyleProps {
  children?: ReactNode
  onClick?: IndifferentHandler
  htmlFor?: string
}

function Label(
  { onClick, tabIndex, ...props }: Props & HTMLAttributes<HTMLLabelElement>,
  ref: Ref<HTMLLabelElement>,
) {
  return <LabelElement ref={ref} {...keyboardClickable({ onClick, tabIndex })} {...props} />
}

/**
 * Use `Label` to wrap an input element and give it label text.
 * @component
 */
export default React.forwardRef<HTMLLabelElement, Props & HTMLAttributes<HTMLLabelElement>>(Label)

export const LabelElement = styled('label', {
  shouldForwardProp: (p: string) => p !== 'spacing' && isPropValid(p),
})<StyleProps>(
  {
    color: color('gray-000'),
    display: 'flex',
    flexGrow: 1,
    lineHeight: 1.78,

    // Labels are focusable for checkboxes, radio options, and others
    ':focus': {
      textDecoration: 'underline',
      outline: 'none',
    },
  },
  ({ onClick }) => (onClick ? { cursor: 'pointer' } : {}),
  variants('spacing', {
    compact: {
      flexDirection: 'row',
      fontSize: fontSize(1),
      fontWeight: 500,
      alignItems: 'center',
    },
    normal: {
      flexDirection: 'column',
      fontWeight: 400,
      fontSize: fontSize(2),
    },
    big: {
      flexDirection: 'column',
      fontWeight: 400,
      fontSize: fontSize(2),
    },
  }),
  variants(
    'validationState',
    {
      error: {
        color: color('danger-500'),
      },
      none: {
        color: color('gray-100'),
      },
      validated: {
        color: color('accent-500'),
      },
    },
    'none',
  ),
  spacing,
  gridItem,
  flexContainer,
)

LabelElement.defaultProps = {
  spacing: 'normal',
  validationState: 'none',
}
