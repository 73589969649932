import { addDays, addHours, addMonths, isSameDay, parseISO, startOfMonth } from 'date-fns'
import { color, fontSize, space } from '@dropscan/ds/tokens'
import * as React from 'react'

import { InputProps } from '@dropscan/forms'
import GoBackIcon from '@dropscan/icons/ui-back.svg'
import GoNextIcon from '@dropscan/icons/ui-forward.svg'

import { Datestamp, toDatestamp } from 'customers/data/Datestamp'
import { toDateObject } from 'customers/data/toDateObject'
import { dayNames, dayRangeForMonth, formatDate } from 'customers/helpers/locale'

import { Heading } from '../components/Heading'
import { Box, BoxProps } from '../core/Box'
import { Flex } from '../core/Flex'
import styled from '../styled'
import { gridItem, GridItemProps } from '../styleFunctions/grid'
import Button from './SpinnerButton'
import { css } from '@emotion/react'

export type CanSelectDayFn = (m: Date) => boolean

type Foo = Pick<React.InputHTMLAttributes<HTMLInputElement>, 'onFocus' | 'onKeyUp'>

interface Props extends Partial<InputProps<Datestamp>>, BoxProps, Foo {
  canSelectDay?: CanSelectDayFn
  onChangeMonth?: (m: Date) => void
}

interface State {
  /** the reference date that will be used as the month/year to show */
  focusedDate?: { date: Date; disabled: boolean }
  currentMonth: {
    ref: Date
    name: string
    days: { date: Date; disabled: boolean }[]
    disabled: boolean
  }
  previousMonth: {
    ref: Date
    name: string
    days: { date: Date; disabled: boolean }[]
    disabled: boolean
  }
  nextMonth: {
    ref: Date
    name: string
    days: { date: Date; disabled: boolean }[]
    disabled: boolean
  }
}

/**
 * A Calendar input
 */
export default class Calendar extends React.Component<Props> {
  state: State
  cells: HTMLDivElement | null = null
  prevMonthButton: HTMLButtonElement | null = null
  nextMonthButton: HTMLButtonElement | null = null

  constructor(props: Props) {
    super(props)
    const refDate = addHours(props.value ? parseISO(props.value) : startOfMonth(new Date()), 12)
    this.state = {
      currentMonth: monthHelper(refDate, props.canSelectDay),
      previousMonth: monthHelper(startOfMonth(addMonths(refDate, -1)), props.canSelectDay),
      nextMonth: monthHelper(startOfMonth(addMonths(refDate, 1)), props.canSelectDay),
    }
  }

  render() {
    const {
      name,
      value,
      onChangeValue,
      disabled,
      onBlur,
      onFocus,
      onKeyUp,
      error,
      validationState,
      ...boxProps
    } = this.props
    const { previousMonth, currentMonth, nextMonth } = this.state

    return (
      <Box bg="white" border="light" borderTopLeftRadius={1} borderTopRightRadius={1} {...boxProps}>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          borderTopLeftRadius={1}
          borderTopRightRadius={1}
        >
          <MonthChangeButton
            ref={x => (this.prevMonthButton = x)}
            aria-label={previousMonth.name}
            onClick={this.showPreviousMonth}
            disabled={this.props.disabled || previousMonth.disabled}
            direction="left"
          >
            <GoBackIcon />
          </MonthChangeButton>
          <Heading level={4}>{`${currentMonth.name} ${currentMonth.ref.getFullYear()}`}</Heading>
          <MonthChangeButton
            ref={x => (this.nextMonthButton = x)}
            aria-label={nextMonth.name}
            onClick={this.showNextMonth}
            disabled={this.props.disabled || nextMonth.disabled}
            direction="right"
          >
            <GoNextIcon />
          </MonthChangeButton>
        </Flex>
        <CellGrid
          ref={cells => (this.cells = cells)}
          tabIndex={0}
          onFocus={this.handleCellsFocus}
          onKeyDown={this.handleCellsKeydown}
        >
          {dayNames().map((s, i) => (
            <Box key={s} gridColumn={i + 1} fg="gray-300" css={cellStyle}>
              {s}
            </Box>
          ))}
          {currentMonth.days.map(({ date, disabled }, idx) => {
            const key = formatDate(date, 'yyyy-MM-dd')
            return (
              <DayButton
                key={key}
                data-date={key}
                gridColumn={(idx % 7) + 1}
                gridRow={Math.floor(idx / 7) + 2}
                disabled={this.props.disabled || disabled}
                dayIsDisabled={disabled}
                tabIndex={-1}
                dayIsSelected={value ? isSameDay(date, toDateObject(value)) : false}
                onClick={this.handleDateClicked}
                onFocus={this.handleDayButtonFocus}
              >
                {formatDate(date, 'd')}
              </DayButton>
            )
          })}
        </CellGrid>
      </Box>
    )
  }

  private showNextMonth = () => {
    this.setState(
      {
        currentMonth: this.state.nextMonth,
        previousMonth: this.state.currentMonth,
        nextMonth: monthHelper(
          startOfMonth(addMonths(this.state.nextMonth.ref, 1)),
          this.props.canSelectDay,
        ),
      },
      () => {
        if (this.props.onChangeMonth) {
          this.props.onChangeMonth(this.state.currentMonth.ref)
        }
      },
    )
  }

  private showPreviousMonth = () => {
    this.setState(
      {
        currentMonth: this.state.previousMonth,
        nextMonth: this.state.currentMonth,
        previousMonth: monthHelper(
          startOfMonth(addMonths(this.state.previousMonth.ref, -1)),
          this.props.canSelectDay,
        ),
      },
      () => {
        if (this.props.onChangeMonth) {
          this.props.onChangeMonth(this.state.currentMonth.ref)
        }
      },
    )
  }

  private handleDateClicked = (evt: React.MouseEvent<HTMLButtonElement>) => {
    const { onChangeValue, value } = this.props
    const dateString = evt.currentTarget.dataset.date
    evt.currentTarget.focus()
    if (dateString && onChangeValue && dateString !== value) {
      onChangeValue(dateString as Datestamp)
    }
  }

  private handleCellsFocus = (evt: React.FocusEvent<HTMLDivElement>) => {
    if (evt.target === evt.currentTarget && evt.eventPhase === Event.CAPTURING_PHASE) {
      let button: HTMLButtonElement | null = null
      if (this.props.value) {
        button = evt.currentTarget.querySelector(`button[data-date="${this.props.value}"]`)
      }
      if (!button) {
        button = evt.currentTarget.querySelector('button[data-date]')
      }
      if (button) {
        button.focus()
      }
    }
  }

  private handleDayButtonFocus = (evt: React.FocusEvent<HTMLButtonElement>) => {
    const dateString = evt.currentTarget.dataset.date
    if (!dateString) {
      return
    }
    const day = parseISO(dateString)
    const focusedDate = this.state.currentMonth.days.find(other => isSameDay(day, other.date))
    console.log('focusing on date', dateString)
    this.setState({ focusedDate })
  }

  private handleCellsKeydown = (evt: React.KeyboardEvent<HTMLDivElement>) => {
    switch (evt.key) {
      case 'Tab':
        if (evt.shiftKey && this.nextMonthButton && this.prevMonthButton) {
          if (this.nextMonthButton.disabled) {
            this.prevMonthButton.focus()
          } else {
            this.nextMonthButton.focus()
          }
          evt.preventDefault()
          evt.stopPropagation()
        }
        //
        return
      case 'ArrowRight':
      case 'ArrowLeft':
      case 'ArrowUp':
      case 'ArrowDown':
        evt.preventDefault()
        evt.stopPropagation()
        this.moveFocus(ArrowToDayDelta[evt.key])
    }
  }

  private moveFocus(delta: number) {
    const focusedButton = this.focusedButton()
    const focusedButtonDate = focusedButton && focusedButton.dataset.date
    const canSelectDay = this.props.canSelectDay || alwaysTrue
    let currentDate = focusedButtonDate
      ? parseISO(focusedButtonDate + 'T12:00:00Z')
      : this.state.currentMonth.ref
    currentDate = addDays(currentDate, delta)

    let i = delta
    while (!canSelectDay(currentDate)) {
      i += delta
      currentDate = addDays(currentDate, delta)
      if (Math.abs(i) > 30) {
        // give up if there's no enabled days within 30 days
        return
      }
    }

    if (!this.state.currentMonth.days.some(day => isSameDay(day.date, currentDate))) {
      if (delta < 0) {
        this.showPreviousMonth()
      } else {
        this.showNextMonth()
      }
    }
    // this is wrapped in setState because we might need to wait for the next/prev month to be visible
    this.setState({}, () => {
      const button =
        this.cells && this.cells.querySelector(`button[data-date="${toDatestamp(currentDate)}"]`)
      if (button) {
        const b = button as HTMLButtonElement
        b.focus()
      }
    })
  }

  private focusedButton(): undefined | HTMLButtonElement {
    const button = this.cells && this.cells.querySelector('button[data-date]:focus')
    if (button) {
      return button as HTMLButtonElement
    }
  }
}

const alwaysTrue = () => true

const ArrowToDayDelta = {
  ArrowRight: 1,
  ArrowLeft: -1,
  ArrowUp: -7,
  ArrowDown: 7,
}

function monthHelper(ref: Date, canSelectDay: (d: Date) => boolean = alwaysTrue) {
  const name = formatDate(ref, 'MMMM')
  const days = dayRangeForMonth(ref.getFullYear(), ref.getMonth()).map(date => ({
    date,
    disabled: !canSelectDay(date),
  }))
  const disabled = days.every(d => d.disabled)

  return {
    ref,
    name,
    days,
    disabled,
  }
}

const MonthChangeButton = styled(Button)<{ direction: 'left' | 'right' }>(
  {
    display: 'flex',
    alignItems: 'center',
    borderStyle: 'none',
    borderRadius: 0,
    cursor: 'pointer',
    paddingTop: space(3),
    paddingBottom: space(3),
    paddingLeft: space(4),
    paddingRight: space(4),

    ':focus': {
      backgroundColor: color('alert-100'),
      transform: '',
    },

    ':hover::after': {
      boxShadow: 'none',
    },

    ':focus::after': {
      boxShadow: 'none',
    },
  },
  ({ direction }) => ({
    [direction === 'left' ? 'borderTopLeftRadius' : 'borderTopRightRadius']: space(1),
  }),
)

const CellGrid = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(7, 1fr)',
  gridGap: 1,
})

const cellStyle = css({
  fontSize: fontSize(2),
  lineHeight: 2.5,
  borderStyle: 'none',
  borderWidth: 0,
  borderRadius: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: color('gray-900'),
  color: color('gray-600'),
})

const DayButton = styled.button<
  GridItemProps & {
    dayIsDisabled: boolean
    dayIsSelected: boolean
  }
>(
  cellStyle,
  {
    fontWeight: 500,
    cursor: 'pointer',
    ':focus': {
      outline: 'none',
    },
  },
  gridItem,
  ({ dayIsSelected, dayIsDisabled }) => ({
    color: dayIsSelected ? color('white') : dayIsDisabled ? color('gray-600') : color('accent-500'),
    backgroundColor: dayIsSelected
      ? color('primary-400')
      : dayIsDisabled
      ? color('gray-900')
      : color('accent-100'),
    ':focus': {
      color: dayIsSelected ? color('white') : color('gray-100'),
      backgroundColor: dayIsSelected ? color('primary-400') : color('primary-100'),
    },
  }),
)
