import * as React from 'react'
import { FrameContextConsumer } from 'react-frame-component'

import watchMediaQueries from '@dropscan/util/watchMediaQueries'

export const MediaQueries = {
  Small: '@media (max-width: 699px)',
  Medium: '@media (min-width: 700px)',
  Large: '@media (min-width: 1100px)',
  Huge: '@media (min-width: 1500px)',
  Gargantuan: '@media (min-width: 1920px)',
}

export type ScreenSize = keyof typeof MediaQueries

export interface State {
  screenSize: ScreenSize
}

const Context = React.createContext<ScreenSize>('Medium')

export function ScreenSizeProvider({ children }: React.PropsWithChildren<{}>) {
  return (
    <FrameContextConsumer>
      {({ window }) => <ScreenSizeSubscriber window={window}>{children}</ScreenSizeSubscriber>}
    </FrameContextConsumer>
  )
}

export function ScreenSizeSubscriber(props: { window: typeof window; children: React.ReactNode }) {
  const size = React.useRef<ScreenSize | undefined>()
  const unsubscribe = React.useRef<() => void>()
  const [, render] = React.useState(0)
  if (!size.current) {
    // bit tricky, but we want to be sure we have the correct screen size on the very first render
    // in the case of static pre-rendering, we default to the 'Large' breakpoint
    if (!props.window || typeof props.window.matchMedia !== 'function') {
      size.current = 'Large'
      unsubscribe.current = () => {}
    } else {
      const { current, cleanup } = watchMediaQueries(
        { queries: MediaQueries, window: props.window },
        screenSize => {
          size.current = screenSize
          setTimeout(() => render(x => x + 1), 1)
        },
      )
      unsubscribe.current = cleanup
      size.current = current
    }
  }

  React.useEffect(() => {
    render(x => {
      if (x !== 0) {
        const { current, cleanup } = watchMediaQueries(
          { queries: MediaQueries, window: props.window },
          screenSize => {
            size.current = screenSize
            setTimeout(() => render(x => x + 1), 1)
          },
        )
        unsubscribe.current = cleanup
        size.current = current
      }
      return x
    })
    return unsubscribe.current
  }, [props.window])
  return <Context.Provider value={size.current!}>{props.children}</Context.Provider>
}

export const ScreenSizeConsumer = Context.Consumer

export const useScreenSize = () => React.useContext(Context)
