import { CSSObject } from '@emotion/react'
import { CSSProperties } from 'react'

import typedKeys from '@dropscan/util/typedKeys'

import { MediaQueries, ScreenSize } from '../ScreenSize'
import { TokenResolver } from '../tokens'

export type Responsive<T> = T | _Responsive<T>
type _Responsive<T> = { [K in ScreenSize | 'default']?: T }
export type ResponsiveCSS<P extends keyof CSSProperties> = Responsive<CSSProperties[P]>

function responsive<T>(
  styles: CSSObject,
  value: { [K in ScreenSize | 'default']?: T },
  cb: (css: CSSObject, value: T) => void,
): void {
  if (typeof value.default !== 'undefined') {
    cb(styles, value.default)
  }
  typedKeys(MediaQueries).forEach(screenSize => {
    const bpValue = value[screenSize]
    if (typeof bpValue !== 'undefined') {
      const query = MediaQueries[screenSize]
      const bpStyles: CSSObject =
        typeof styles[query] === 'object'
          ? (styles[query] as CSSObject)
          : (styles[query] = {} as CSSObject)
      cb(bpStyles, bpValue)
    }
  })
}

export function assignComputed<K extends keyof CSSProperties, T>(
  styles: CSSObject,
  cssProp: K,
  fn: (v: T) => CSSProperties[K],
  value: Responsive<T> | undefined,
) {
  if (typeof value === 'undefined') {
    return
  }
  if (typeof value === 'object' && value !== null) {
    responsive(styles, value, (css, v) => {
      Object.assign(css, { [cssProp]: fn(v) })
    })
  } else {
    const computed = fn(value)
    Object.assign(styles, { [cssProp]: computed })
  }
}

export function assignLiteral<K extends keyof CSSProperties>(
  styles: CSSObject,
  cssProp: K,
  value?: Responsive<CSSProperties[K]>,
) {
  if (typeof value === 'undefined') {
    return
  } else if (typeof value === 'object') {
    responsive(styles, (value as unknown) as _Responsive<string | number>, (css, v) => {
      css[cssProp] = v as CSSObject[K]
    })
  } else {
    Object.assign(styles, { [cssProp]: value })
  }
}

export function assignToken<K extends keyof CSSProperties, Token>(
  styles: CSSObject,
  resolve: TokenResolver<Token>,
  cssProp: K,
  value?: Responsive<Token>,
) {
  assignComputed(styles, cssProp, v => resolve(v) as CSSProperties[K], value)
}

export function assignNamedStyle<V extends { [key: string]: CSSObject | undefined }>(
  styles: CSSObject,
  variants: V,
  value?: Responsive<keyof V>,
) {
  if (typeof value === 'string' && variants[value]) {
    Object.assign(styles, variants[value])
  }
  if (typeof value === 'object') {
    responsive(styles, value, (css, key) => {
      assignNamedStyle(css, variants, key)
    })
  }
}
