import { Flex } from '../core/Flex'
import styled from '../styled'
import { radius } from '../tokens'

/**
 * ButtonGroup bundles a set of buttons together into a single block with rounded corners.
 *
 * @component
 */
export default styled(Flex)({
  overflow: 'hidden',

  '& button': {
    borderWidth: '1px',
    borderRadius: 0,
    borderStyle: 'solid',
  },

  '& > button:not(:first-of-type):not(:hover)': {
    borderLeftColor: 'transparent',
  },

  '& button:last-child': {
    borderTopRightRadius: radius(1),
    borderBottomRightRadius: radius(1),
  },
})
