import deepEqual from 'deep-equal'
import * as React from 'react'

import { InputProps } from '@dropscan/forms'

import Label, { Props as LabelProps } from '../forms/Label'

export interface Props<T> extends Partial<InputProps<T>>, LabelProps {
  labelText?: React.ReactNode
  optionGroups: [string, [T, string, boolean?][]][]
  noneOptionLabel?: string
  children?: React.ReactNode
}

/**
 * `GroupedSelect` accepts a set of `optionGroups` in place of the single `options` list used by [`Select`](#select).
 */
export default class GroupedSelect<T> extends React.PureComponent<Props<T>> {
  static of<U>(): React.ComponentType<Props<U>> {
    return this
  }

  render() {
    const {
      labelText,
      optionGroups,
      value,
      disabled,
      children,
      onChangeValue,
      noneOptionLabel,
      name,
      ...labelProps
    } = this.props

    const htmlValue = this.findValue(value)

    return (
      <Label {...labelProps}>
        {labelText}
        <select name={name} value={htmlValue} disabled={disabled} onChange={this.handleChange}>
          <option value="-1">{noneOptionLabel || '--'}</option>
          {optionGroups.map(
            ([groupLabel, options], groupIdx) =>
              options.length > 0 && (
                <optgroup key={groupLabel} label={groupLabel}>
                  {options.map(([_, optLabel, optDisabled], idx) => (
                    <option key={idx} value={`${groupIdx}.${idx}`} disabled={optDisabled}>
                      {optLabel}
                    </option>
                  ))}
                </optgroup>
              ),
          )}
        </select>
        {children}
      </Label>
    )
  }

  handleChange = (evt: React.SyntheticEvent<HTMLSelectElement>) => {
    const { optionGroups } = this.props
    const [groupIdx, idx] = evt.currentTarget.value.split('.').map(s => parseInt(s, 10))

    if (groupIdx < 0) {
      return
    }

    const group = optionGroups[groupIdx]
    if (!Array.isArray(group)) {
      throw new Error(`programming error, option group ${groupIdx} does not exist`)
    }
    const option = group[1][idx]
    if (!Array.isArray(option)) {
      throw new Error(`programming error, option ${groupIdx}.${idx} does not exist`)
    }

    if (this.props.onChangeValue) {
      this.props.onChangeValue(option[0])
    }
  }

  private findValue(value: T | undefined): string {
    if (typeof value === 'undefined') {
      return '-1'
    }

    for (
      let groupIdx = 0, groupsLen = this.props.optionGroups.length;
      groupIdx < groupsLen;
      groupIdx++
    ) {
      const [, group] = this.props.optionGroups[groupIdx]
      for (let valIdx = 0, valsLen = group.length; valIdx < valsLen; valIdx++) {
        if (deepEqual(value, group[valIdx][0])) {
          return `${groupIdx}.${valIdx}`
        }
      }
    }
    return '-1'
  }
}
