import isPropValid from '@emotion/is-prop-valid'
import { CSSProperties } from 'react'

import styled, { Theme } from '../styled'
import { ColorProps, colors } from '../styleFunctions/colors'
import { font, FontProps, isFontProp } from '../styleFunctions/font'
import { spacing, SpacingProps } from '../styleFunctions/spacing'

export interface TextProps extends FontProps, ColorProps, SpacingProps {
  preset?: keyof Theme['textPresets']
  display?: CSSProperties['display']
  lineHeight?: CSSProperties['lineHeight']
  letterSpacing?: CSSProperties['letterSpacing']
}

/**
 * `Text` is used for non-interactive copy in the application. It supports all of the props described in the [typography](#theme-typography) section of the theme.
 *
 * Note `display: block` is the default! This is what you want 99% of the time for UI, but it's unintuitive when nesting `<Text>` in paragraphs.
 *
 * @component
 */
export const Text = styled('span', {
  shouldForwardProp: prop => !isFontProp(prop) && prop !== 'color' && isPropValid(prop),
})<TextProps>(
  font,
  colors,
  spacing,
  ({ theme, preset }) => (preset ? theme.textPresets[preset] : {}),
  ({ display = 'block', lineHeight, letterSpacing }) => ({
    display,
    lineHeight,
    letterSpacing,
  }),
)

Text.defaultProps = {
  fontSize: 2,
  fontWeight: 400,
}

export default Text
